/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable camelcase */
/* eslint-disable no-console */
import {
  memo,
  FC,
  ReactNode,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { ToastContainer } from 'react-toastify';
import { useRouter } from 'next/router';
import cn from 'clsx';
import { getDeviceId } from '@amplitude/analytics-browser';
import { getRegion } from '@framework/api/utils/maxify';
import type { DatoProduct, GetNavPayload } from '@framework/api/types';
import {
  DEFAULT_LOCALE,
  SUPPORTED_LOCATION,
  GEOIP_CONTINENT_TO_LOCATION_MAP,
  COUNTRY_CODE_TO_COUNTRY_NAME_MAP,
  COUNTRY_CODE_TO_REGION_NAME_MAP,
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LANGUAGE_CODE,
  ROUTE_BUILD,
} from '@constants';
import useGeoStore, { getGeo, getSetGeo } from '@stores/use-geo-store';
import useUiStore, {
  getDisplayOverlay,
  getClickOverlay,
  getClosePreviewBanner,
  getDisplayPreviewBanner,
  getShowPreviewBanner,
  getOpenMarketingBanner,
  getCloseMarketingBanner,
  getDisplayCart,
  getCloseCart,
  getDisplayNav,
  getCloseNav,
  getDisplayMarketingBanner,
} from '@stores/use-ui-store';
import useToastStore, {
  getDismissedToast,
  getDisplayToast,
  getOpenToast,
  getCloseToast,
  getSetToastText,
  getSetToastSmallText,
  getSetToastCountryText,
  getSetToastButtonText,
  getSetToastAction,
  getSetToastShop,
  getSetToastCountryLink,
} from '@stores/use-toast-store';
import { getGeoipCookie, setGeoipCookie } from '@utils/geoip-cookie';
import { setAmplitudeCookie } from '@utils/amplitude-cookie';
import { setImpactCookie } from '@utils/impact-cookie';
import { useTranslation as t, useMedia, useExperiment } from '@utils/hooks';
import parseLocale from '@utils/parse-locale';
import { CartSidebarView, CartConfirmationView } from '@components/Cart';
import Sidebar from '@components/Sidebar';
import Header from './Header';
import Footer from './Footer';
import Modals from './Modals';
import MobileMenu from './Header/MobileMenu';
import customStyles from './Layout.module.css';
import * as styles from './styles';
import 'react-toastify/dist/ReactToastify.css';

type Props = {
  children?: ReactNode;
  pageProps: {
    nav?: GetNavPayload;
    preview?: boolean;
    addMarketingBanner?: boolean;
    data: DatoProduct;
  };
};

const defaultNav = { headerNavigation: null, footerNavigation: null };

const Layout: FC<Props> = ({ children, pageProps }) => {
  const router = useRouter();
  const { pathname } = useRouter();
  const { locale = DEFAULT_LOCALE, defaultLocale, asPath } = router;
  const {
    nav = defaultNav,
    preview = false,
    data: { isDark, isPrebuild } = { isDark: false, isPrebuild: false },
  } = pageProps;
  const region = getRegion(locale);
  const headerRef = useRef<HTMLDivElement>();
  const [navHeight, setNavHeight] = useState(101);
  const isSm = useMedia('sm');
  const isMd = useMedia('md');
  const isLg = !isSm && !isMd;
  const isCartPage = pathname.includes('cart');
  const theme = isDark ? 'dark' : 'light';
  const pdpConfig = isPrebuild ? 'pdpconfig' : null;
  const amplitudeDeviceId = getDeviceId();

  const { headerNavigation, footerNavigation } = nav;
  const marketingBanner = headerNavigation?.marketingBanner ?? null;

  const regionalHeaderPanes = useMemo(
    () =>
      Array.isArray(headerNavigation?.panes)
        ? headerNavigation.panes.filter(
            c =>
              c.availableRegions?.some(r => r.region === region) ||
              c.availableRegions.length === 0
          )
        : null,
    [headerNavigation, region]
  );

  const bestSellersVariant = useExperiment('show-best-sellers-in-nav');
  let regionalPanesFiltered = regionalHeaderPanes;

  const showBestSellers = bestSellersVariant === 'treatment';
  if (!showBestSellers) {
    regionalPanesFiltered = regionalHeaderPanes?.filter(
      pane => pane.idForTests !== 'best_sellers'
    );
  }

  const geo = useGeoStore(getGeo);
  const setGeo = useGeoStore(getSetGeo);

  const displayToast = useToastStore(getDisplayToast);
  const dismissedToast = useToastStore(getDismissedToast);
  const openToast = useToastStore(getOpenToast);
  const closeToast = useToastStore(getCloseToast);
  const setToastText = useToastStore(getSetToastText);
  const setToastSmallText = useToastStore(getSetToastSmallText);
  const setToastCountryText = useToastStore(getSetToastCountryText);
  const setToastButtonText = useToastStore(getSetToastButtonText);
  const setToastAction = useToastStore(getSetToastAction);
  const setToastShop = useToastStore(getSetToastShop);
  const setToastCountryLink = useToastStore(getSetToastCountryLink);

  const displayNav = useUiStore(getDisplayNav);
  const closeNav = useUiStore(getCloseNav);

  const openMarketingBanner = useUiStore(getOpenMarketingBanner);
  const closeMarketingBanner = useUiStore(getCloseMarketingBanner);
  const displayPreviewBanner = useUiStore(getDisplayPreviewBanner);
  const showPreviewBanner = useUiStore(getShowPreviewBanner);
  const closePreviewBanner = useUiStore(getClosePreviewBanner);
  const displayOverlay = useUiStore(getDisplayOverlay);
  const clickOverlay = useUiStore(getClickOverlay);
  const displayMarketingBanner = useUiStore(getDisplayMarketingBanner);

  const displayCart = useUiStore(getDisplayCart);
  const closeCart = useUiStore(getCloseCart);

  useEffect(() => {
    const fetchGeo = async (): Promise<void> => {
      const result = await fetch(
        `${process.env.NEXT_PUBLIC_ATLAS_API}/api/geoip-by-headers`
      ).then(r => r.json());

      setGeoipCookie(result);
      setGeo(result);
    };

    const geoCookie = getGeoipCookie();

    if (geoCookie) {
      setGeo(geoCookie);
    } else {
      fetchGeo();
    }
  }, [setGeo]);

  useEffect(() => {
    setAmplitudeCookie(amplitudeDeviceId);
  }, [amplitudeDeviceId]);

  const CURRENTLY_BROWSING = t('currently_browsing');
  const BANNER_BUTTON = t('banner_button');
  const SHOP = t('banner_country_text_shop');
  const banner_region_text = t('banner_region_text');
  const banner_country_text = t('banner_counrty_text');

  const regionalMarketingBanner = useMemo(
    () =>
      Array.isArray(marketingBanner)
        ? marketingBanner.filter(c =>
            c.bannerRegion?.some(r => r.region === region)
          )
        : null,
    [marketingBanner, region]
  );

  useEffect(() => {
    if (typeof geo !== 'undefined' && geo.continentCode) {
      const parsedLocale = parseLocale(defaultLocale, locale);
      const lang = parsedLocale[0] || DEFAULT_LANGUAGE_CODE;
      const location = parsedLocale[1] || DEFAULT_COUNTRY_CODE;
      const desiredLocation = GEOIP_CONTINENT_TO_LOCATION_MAP(geo);
      const currentlyBrowsingText = (): void =>
        setToastSmallText(CURRENTLY_BROWSING);

      // user's geoip location doesn't match location in URL
      if (desiredLocation !== location && !dismissedToast) {
        setToastAction(() => {
          router.push(asPath, asPath, {
            locale: `${lang.toLowerCase()}-${desiredLocation.toUpperCase()}`,
          });

          if (desiredLocation === location) {
            closeToast();
          }
        });

        if (desiredLocation === SUPPORTED_LOCATION[geo.countryCode]) {
          currentlyBrowsingText();
          setToastCountryText(
            COUNTRY_CODE_TO_COUNTRY_NAME_MAP[location] || location
          );
          setToastShop(`${SHOP}`);
          setToastCountryLink(
            `${
              COUNTRY_CODE_TO_COUNTRY_NAME_MAP[desiredLocation] ||
              desiredLocation
            }`
          );
          setToastText(banner_country_text);
        } else {
          currentlyBrowsingText();
          setToastCountryText(
            COUNTRY_CODE_TO_REGION_NAME_MAP[location] || location
          );
          setToastText(banner_region_text);
          setToastButtonText(
            `${BANNER_BUTTON} ${
              COUNTRY_CODE_TO_REGION_NAME_MAP[desiredLocation] ||
              desiredLocation
            }`
          );
        }

        openToast();
      } else if (displayToast) {
        closeToast();
      }

      if (
        Array.isArray(regionalMarketingBanner) &&
        regionalMarketingBanner.length > 0
      ) {
        openMarketingBanner();
      } else {
        closeMarketingBanner();
      }

      if (preview) {
        showPreviewBanner();
      } else {
        closePreviewBanner();
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [geo, asPath, locale]);

  useEffect(() => {
    const { irclickid } = router.query;

    if (irclickid) {
      setImpactCookie(Array.isArray(irclickid) ? irclickid[0] : irclickid);
    }
  }, [router.query]);

  const contextClass = {
    success: customStyles.progressSuccess,
    error: customStyles.progressError,
    info: customStyles.progressInfo,
    warning: customStyles.progressWarning,
    default: customStyles.progressDefault,
  };

  const cartView =
    process.env.NEXT_PUBLIC_FEATURE_CONFIRMATION_DRAWER === 'true' ? (
      <CartConfirmationView cartNotice={headerNavigation?.cartNotice} />
    ) : (
      <CartSidebarView />
    );

  const cartSideBar = !isCartPage ? (
    <Sidebar
      open={displayCart}
      onClose={closeCart}
      isRight
      isFullWidth
      isFullHeight
    >
      {cartView}
    </Sidebar>
  ) : null;

  const mobileNavSideBar = (
    <Sidebar
      open={displayNav}
      onClose={() => closeNav()}
      isMobileOnly
      isFullWidth
      isFullHeight
    >
      <MobileMenu regionalPanes={regionalPanesFiltered} />
    </Sidebar>
  );

  useEffect(() => {
    if (headerRef?.current) {
      const { height } = headerRef.current.getBoundingClientRect();

      setNavHeight(height);
    }
  }, [
    navHeight,
    displayToast,
    displayMarketingBanner,
    headerRef,
    isSm,
    isMd,
    isLg,
  ]);

  return (
    <>
      <div
        id="page-wrapper"
        className={cn(styles.pageWrapper, theme, pdpConfig, {
          [styles.pdpConfig]: pdpConfig,
        })}
      >
        <div id="nav-wrapper" ref={headerRef} className={styles.navWrapper}>
          <Header
            data={headerNavigation}
            regionalPanes={regionalPanesFiltered}
            displayPreviewBanner={displayPreviewBanner}
          />
          <div
            className="absolute"
            style={{ top: navHeight > 57 ? `${navHeight}px` : '0px' }}
          >
            {cartSideBar}
            {mobileNavSideBar}
          </div>
        </div>

        <div id="main-wrapper" className={styles.mainWrapper}>
          {isCartPage && displayCart
            ? null
            : displayOverlay && (
                <div
                  className={styles.overlay}
                  onClick={clickOverlay}
                  onKeyDown={clickOverlay}
                  role="button"
                  tabIndex={0}
                  aria-label="close overlay"
                />
              )}
          <main
            id="main"
            className={cn(styles.stickFooterContent, customStyles.mainSpacing)}
          >
            {children}
          </main>
          <Footer data={footerNavigation} />
        </div>
      </div>
      <Modals
        background={pathname.includes(`${ROUTE_BUILD}`) ? 'dark' : 'light'}
      />
      <ToastContainer
        icon={false}
        toastClassName={({ type }) => contextClass[type || 'default']}
      />
    </>
  );
};

export default memo(Layout);
